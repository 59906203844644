.content {
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2vw;
}

.login {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
